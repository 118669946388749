var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-full"},[_c('div',{staticClass:"drawer-header d-flex align-center mb-4"},[_c('span',{staticClass:"font-weight-semibold text-base text--primary"},[_vm._v(_vm._s(_vm.eventLocal.id ? 'Update' : 'Add')+" Event")]),_c('v-spacer'),(_vm.eventLocal.id)?_c('v-btn',{staticClass:"me-1",attrs:{"icon":"","small":""},on:{"click":function($event){_vm.$emit('remove-event'); _vm.$emit('close-drawer')}}},[_c('v-icon',{attrs:{"size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiTrashCanOutline)+" ")])],1):_vm._e(),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.$emit('close-drawer')}}},[_c('v-icon',{attrs:{"size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1)],1),_c('perfect-scrollbar',{staticClass:"ps-calendar-event-handler",attrs:{"settings":_vm.perfectScrollbarSettings}},[_c('v-form',{ref:"refCalendarEventHandlerForm",staticClass:"px-5 pt-3 pb-10",on:{"submit":function($event){$event.preventDefault();return _vm.handleFormSubmit.apply(null, arguments)}}},[_c('v-text-field',{staticClass:"mb-6",attrs:{"label":"Name","placeholder":"Name","outlined":"","rules":[_vm.validators.required],"hide-details":"auto","dense":""},model:{value:(_vm.eventLocal.name),callback:function ($$v) {_vm.$set(_vm.eventLocal, "name", $$v)},expression:"eventLocal.name"}}),_c('v-select',{staticClass:"mb-6",attrs:{"outlined":"","label":"Calendar","placeholder":"Calendar","item-text":function (item) { return item.label; },"item-value":function (item) { return item.value; },"items":_vm.$store.state['app-calendar'].calendarOptions,"rules":[_vm.validators.required],"hide-details":"auto","menu-props":{ offsetY: true },"dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-badge',{staticClass:"me-2",attrs:{"inline":"","color":item.color,"dot":""}}),_c('span',[_vm._v(_vm._s(item.label))])],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-badge',{staticClass:"me-2 mb-1",attrs:{"inline":"","color":item.color,"dot":""}}),_c('span',[_vm._v(_vm._s(item.label))])],1)]}}]),model:{value:(_vm.eventLocal.extendedProps.calendar),callback:function ($$v) {_vm.$set(_vm.eventLocal.extendedProps, "calendar", $$v)},expression:"eventLocal.extendedProps.calendar"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","eager":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mb-6",attrs:{"label":"Start Date","readonly":"","outlined":"","dense":"","hide-details":"auto","rules":[_vm.validators.required]},model:{value:(_vm.eventLocal.start),callback:function ($$v) {_vm.$set(_vm.eventLocal, "start", $$v)},expression:"eventLocal.start"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.isStartDateMenuOpen),callback:function ($$v) {_vm.isStartDateMenuOpen=$$v},expression:"isStartDateMenuOpen"}},[_c('v-date-picker',{on:{"input":function($event){_vm.isStartDateMenuOpen = false}},model:{value:(_vm.eventLocal.start),callback:function ($$v) {_vm.$set(_vm.eventLocal, "start", $$v)},expression:"eventLocal.start"}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mb-6",attrs:{"label":"End Date","readonly":"","outlined":"","dense":"","hide-details":"auto","rules":[_vm.validators.required, _vm.validators.endDateValidator]},model:{value:(_vm.eventLocal.end),callback:function ($$v) {_vm.$set(_vm.eventLocal, "end", $$v)},expression:"eventLocal.end"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.isEndDateMenuOpen),callback:function ($$v) {_vm.isEndDateMenuOpen=$$v},expression:"isEndDateMenuOpen"}},[_c('v-date-picker',{on:{"input":function($event){_vm.isEndDateMenuOpen = false}},model:{value:(_vm.eventLocal.end),callback:function ($$v) {_vm.$set(_vm.eventLocal, "end", $$v)},expression:"eventLocal.end"}})],1),_c('v-switch',{staticClass:"mt-0 mb-7 d-inline-flex",attrs:{"label":"Timed","hide-details":""},model:{value:(_vm.eventLocal.timed),callback:function ($$v) {_vm.$set(_vm.eventLocal, "timed", $$v)},expression:"eventLocal.timed"}}),_c('v-text-field',{staticClass:"mb-6",attrs:{"type":"url","outlined":"","dense":"","hide-details":"auto","label":"Event URL","placeholder":"Event URL","rules":[_vm.validators.urlValidator]},model:{value:(_vm.eventLocal.extendedProps.url),callback:function ($$v) {_vm.$set(_vm.eventLocal.extendedProps, "url", $$v)},expression:"eventLocal.extendedProps.url"}}),_c('v-select',{staticClass:"mb-6 select-guest",attrs:{"outlined":"","chips":"","small-chips":"","deletable-chips":"","multiple":"","dense":"","hide-details":"auto","label":"Guests","placeholder":"Guests","menu-props":{ offsetY: true, contentClass: 'list-style' },"item-text":function (guest) { return guest.name; },"item-value":function (guest) { return guest.name; },"items":_vm.guestsOptions},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{staticClass:"me-2",attrs:{"size":"25"}},[_c('v-img',{attrs:{"src":item.avatar}})],1),_c('span',{staticClass:"text-sm"},[_vm._v(_vm._s(item.name))])],1)]}}]),model:{value:(_vm.eventLocal.extendedProps.guests),callback:function ($$v) {_vm.$set(_vm.eventLocal.extendedProps, "guests", $$v)},expression:"eventLocal.extendedProps.guests"}}),_c('v-text-field',{staticClass:"mb-6",attrs:{"label":"Location","dense":"","hide-details":"auto","placeholder":"Location","outlined":""},model:{value:(_vm.eventLocal.extendedProps.location),callback:function ($$v) {_vm.$set(_vm.eventLocal.extendedProps, "location", $$v)},expression:"eventLocal.extendedProps.location"}}),_c('v-textarea',{attrs:{"outlined":"","label":"Description","placeholder":"Description"},model:{value:(_vm.eventLocal.extendedProps.description),callback:function ($$v) {_vm.$set(_vm.eventLocal.extendedProps, "description", $$v)},expression:"eventLocal.extendedProps.description"}}),_c('v-btn',{staticClass:"me-3",attrs:{"color":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.eventLocal.id ? 'Update' : 'Add ')+" ")]),_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.resetEventLocal}},[_vm._v(" Reset ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }